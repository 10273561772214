@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&family=Zen+Kaku+Gothic+New:wght@400;700&display=swap');

body{
  @apply text-rhino-950;
  font-family: 'Noto Sans JP', sans-serif;
  text-align: center;
}
h1, h2, h3, h4, h5, .title{
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-weight: 700;
}

.fade-in-out {
  transition: opacity 0.3s ease-in-out;
}

.fade-in-out:hover {
  opacity: 0.7;
}

.global-navigation-area ul li{
  @apply mt-4;  
}
.logo-wrapper{
  @apply w-[228px] h-[54px]
}
.logo{
  @apply w-2/3 sm:w-full;
}

.button{
  @apply  text-white bg-blaze-orange-600 hover:text-blaze-orange-600 hover:bg-white hover:border-3 hover:border-blaze-orange-600
  focus:outline-none focus:ring-4 focus:ring-yellow-300 font-bold rounded-full text-xl px-8 py-1.5 text-center me-2 ms-0 md:ms-8 mb-2 font-black border-3 border-blaze-orange-600;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}
.contact-button{
  @apply text-white bg-blaze-orange-600 hover:text-blaze-orange-600 hover:bg-white hover:border-3 hover:border-blaze-orange-600 font-bold rounded-full text-base md:text-xl px-8 py-1.5 text-center me-2 ms-0 md:ms-8 mb-2 font-black border-3 border-blaze-orange-600;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

#main-visual{
  @apply bg-center bg-no-repeat bg-cover bg-gray-700 mt-16 sm:mt-4;
  background-image: url('images/bg-main-visual.png');
}
@media (max-width: 640px) 
{
  #main-visual{
    background-image: url('images/bg-main-visual-sp.png');
  }
}

.features-info{
  @apply bg-banana-mania-100 pb-8 relative pt-5;
}

.features-info::before{
  @apply content-[''] w-full block bg-transparent bg-no-repeat bg-bottom bg-contain absolute;
  top: -6rem;
  height: 6rem;
  background-image: url('images/frame-wave-top-yellow.svg');
}

.features-info::after{
  @apply content-[''] w-full block bg-transparent bg-no-repeat bg-top bg-contain absolute z-10;
  bottom: -6rem;
  height: 6rem;
  background-image: url('images/frame-wave-bottom-yellow.svg');
}

.features-info ul li{
  @apply flex items-center lg:block
}

.features-info ul li img{
  @apply basis-2/5 sm:basis-1/2 items-start w-1/3 sm:w-1/2 lg:w-full
}

.features-info ul li p{
  @apply basis-3/5 place-content-center
}

.usecase-info{
  @apply bg-neutral-100 pb-8 relative pt-24 min-h-[1500px] 2xl:min-h-[1050px]  3xl:min-h-[1000px];
}
.usecase-list{
  @apply flex-none xl:flex xl:flex-wrap
}
.usecase-list li{
  @apply xl:h-[500px] xl:w-[500px] relative text-xl mx-auto
}
.usecase-list li h4{
  @apply bg-no-repeat h-[140px] w-[140px] bg-top pt-8 text-white absolute z-10;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}
.usecase-list li .usecase-text{
  @apply bg-no-repeat h-full xl:h-full xl:w-[450px] pt-28
}

.usecase-list li .usecase-text p{
  @apply pb-8
}

.usecase-list li .usecase-image {
  @apply ml-[calc((100%-245px)/2)] w-[60%] sm:w-full;
}

.usecase-01 h4{
  @apply -top-2 left-auto sm:left-[calc(55%-250px)];
  background-image: url('images/orange-clouds-mist-01.png'); 
}
.usecase-01 .usecase-text{
  background-image: url('images/clouds-mist-01.png'); 
  background-position: 54% -85px;
}
.usecase-01 .usecase-text-01{
  @apply pt-20 pb-24;
}
.usecase-01 .usecase-image{
  @apply -mt-24 xl:-mt-48 2xl:-mt-48;
}
.usecase-02 h4{
  @apply -top-2 left-auto sm:left-[calc(55%-250px)];
  background-image: url('images/orange-clouds-mist-02.png');  
}
.usecase-02 .usecase-text{
  @apply pb-8;
  background-image: url('images/clouds-mist-02.png');  
  background-position: 54% -85px;
}
.usecase-02 .usecase-text-02{
  @apply pt-20 pb-20;
}
.usecase-02 .usecase-image{
  @apply -mt-24 xl:-mt-48 2xl:-mt-48;
}
.usecase-03 h4{
  @apply -top-2 left-auto sm:left-[calc(55%-250px)];
  background-image: url('images/orange-clouds-mist-03.png');  
}
.usecase-03 .usecase-text{
  @apply pb-24;
  background-image: url('images/clouds-mist-03.png');  
  background-position: 54% -25px;
}
.usecase-03 .usecase-image{
  @apply -mt-20 xl:-mt-48;
}

.support-info{
  @apply pb-8 relative pt-32 bg-no-repeat lg:bg-cover;
  background-image: url('images/bg-support-info.png');
  background-position-y: 250px;
}
@media (max-width: 1024px) 
{
  .support-info{
    background-image: url('images/bg-support-info-sp.png');
    background-size: cover;
    background-position: 0px 340px;
  }
}

.support-info::before{
  @apply content-[''] w-full block bg-transparent bg-no-repeat bg-bottom bg-contain absolute;
  top: -6rem;
  height: 6rem;
  background-image: url('images/frame-wave-top-white.svg');
}
.support-info h4{
  @apply text-2xl text-rhino-950 font-bold pb-8
}
.support-info ul li{
  @apply lg:flex lg:items-start mb-16;
}
.support-info ul li div{
  @apply pt-24;
}
.support-info ul li img{
  @apply pt-4 lg:pt-24 lg:w-1/2;
}

.contact-area p.message{
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

.slider-area{
  @apply bg-neutral-100 pb-8 relative pt-24
}
.slider-area::before{
  @apply content-[''] w-full block bg-transparent bg-no-repeat bg-bottom bg-contain absolute;
  top: -6rem;
  height: 6rem;
  background-image: url('images/frame-wave-top-gray.svg');
}

.slider-area .slider .slide{
  @apply bg-no-repeat bg-contain bg-center overflow-hidden w-[600px] h-auto border-2 border-slate-700 bg-white w-3/5 flex text-center rounded-3xl px-4 pt-8 sm:pt-12 sm:p-10 min-h-[480px] xl:min-h-[500px];
  justify-content: center;
  box-shadow: 10px 10px 0px 0px rgb(49, 60, 88);
  width: 90%!important;
}

.slider-area .slider .slick-list{
  @apply !pb-12;
}
.slider-area .slider .slide p{
  @apply text-left sm:text-center;
}
.slider-area .slider .slide img{
  @apply text-center mx-auto mb-10 sm:w-1/2;
}

.slick-prev:before, .slick-next:before {
  color: rgb(49, 60, 88)!important;
}

.service-navigation{
  @apply bg-neutral-100 pb-8 relative pt-24
}

.service-navigation ul li{
  @apply text-sm sm:text-base basis-1/3 sm:basis-1/4 text-center py-4
}

.service-navigation ul li img{
  @apply mx-auto mb-4 h-[63px];
}

.use-flow-body{
  @apply bg-neutral-100 pb-8 relative pt-24
}

.use-flow-body:before{
  @apply content-[''] w-full block bg-transparent bg-no-repeat bg-bottom bg-contain absolute;
  top: -6rem;
  height: 6rem;
  background-image: url('images/frame-wave-top-gray.svg');
}

.use-flow-steps{
  @apply w-4/5 mx-auto relative;
}

.use-flow-steps::after{
  @apply content-[''] hidden md:block absolute;
  width: 3px;
  background-color: #C4C4C4;
  height: calc(100% - 50px);
  top: 30px;
  left: 47px;
}

.use-flow-steps li{
  @apply flex items-center mb-8 md:mb-24 relative ;
}
.use-flow-steps li img{
  @apply w-[32px] md:w-auto;
  z-index: 1;
}


.use-flow-steps li:last-child:after{
  @apply hidden;
}

.use-flow-steps li dl{
  @apply bg-white rounded-[30px] mx-8 p-4 md:flex basis-4/5 items-center w-full;
}

.use-flow-steps li dl dt{
  @apply basis-2/5 text-slate-700 text-2xl lg:text-3xl font-black mb-4 sm:mb-4 md:mb-0;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}
.use-flow-steps li dl dt .sub{
  @apply text-2xl;
}

.use-flow-steps li dl dd{
  @apply basis-3/5 text-left text-slate-700 text-base font-medium w-full;
}

.use-flow-steps li dl dd a{
  @apply  text-base font-medium;
  color: #0AD9F5;
}

.use-flow-slider{
  @apply mb-16 w-4/5 mx-auto;
}

/* 
.use-flow-slider .slide{
  @apply !w-4/5;
}
.use-flow-slider img{
  @apply w-full block;
} */

.speech-bubble{
  @apply mx-auto mb-2 relative h-[60px] bg-blaze-orange-600 text-2xl md:text-3xl rounded-full px-12 sm:px-20 pt-3 md:pt-4;
  min-width:250px;
  width: max-content;
  text-align:center;
  color:#FFFFFF;
  font-weight:bold;
}

.speech-bubble:after{
  border: solid transparent;
  content:'';
  height:0;
  width:0;
  pointer-events:none;
  position:absolute;
  border-color: rgba(245, 101, 12, 0);
  border-top-width:20px;
  border-bottom-width:10px;
  border-left-width:10px;
  border-right-width:10px;
  margin-left: -10px;
  border-top-color:#F5650C;
  top:100%;
  left:50%;
}

.light-blue{
  background-color: #0AD9F5;
}

.light-blue:after{
  border-top-color:#0AD9F5;
}

.dark-blue{
  background-color: #313C58;
}

.dark-blue:after{
  border-top-color:#313C58;
}

.function-info-area{
  @apply bg-banana-mania-100 pb-8 pt-40 pb-40 relative;
}

.function-info-area::before{
  @apply content-[''] w-full block bg-transparent bg-no-repeat bg-bottom bg-contain absolute;
  top: -6rem;
  height: 6rem;
  background-image: url('images/frame-wave-top-yellow.svg');
}

.function-info-box{
  @apply mt-16 rounded-lg w-full md:w-4/5 px-4 mx-auto;
  background-color: #FFFBF0;
  max-width: 1100px;
}
.function-info-box h5{
  @apply text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold pb-4 pt-8 sm:pt-20;
}

.function-info-box ul{
  @apply block md:flex md:gap-4 pb-1 sm:pb-12 lg:px-20
}

.function-info-box ul li img{
  @apply mx-auto mb-8;
}

.function-info-box ul li p{
  @apply md:w-[300px] mx-auto text-left mb-12 sm:mb-4;
}

.other-functions dl{
  @apply md:flex gap-4 my-4 w-4/5 mx-8 md:mx-auto ;
}
.other-functions dt{
  @apply md:basis-1/4 rounded-lg text-2xl font-bold text-slate-700 items-center flex justify-center mb-2 sm:mb-0 py-2;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  background-color: #F0FBFF;
}
.other-functions dd{
  @apply md:basis-3/4 rounded-lg text-lg font-bold text-slate-700;
  background-color: #FFFBF0;
}
.other-functions dd ul{
  @apply lg:flex flex-wrap py-8 ps-12 gap-x-16 gap-y-4 justify-start;
}
.other-functions dd ul li{
  @apply md:basis-4/5 lg:basis-1/4 text-left my-2 lg:my-4 me-4 md:me-0 relative;
}
.other-functions dd ul li::before{
  @apply content-['●'] text-slate-200 absolute top-0 -left-[25px];
}


.example-area{
  @apply pb-8 relative pt-32 bg-neutral-100;
}
.example-area::before{
  @apply content-[''] w-full block bg-transparent bg-no-repeat bg-bottom bg-contain absolute;
  top: -6rem;
  height: 6rem;
  background-image: url('images/frame-wave-top-gray.svg');
}

.example-box{
  @apply mt-16 rounded-lg w-full md:w-4/5 mx-auto;
  max-width: 1100px;
}
.example-box h5{
  @apply text-xl sm:text-xl md:text-3xl bg-rhino-950 text-white font-bold py-2 text-center rounded-full mb-8;
}
.example-box p{
  @apply text-left mx-4;
}
.example-box ul{
  @apply sm:flex sm:flex-wrap md:flex-nowrap md:gap-4 my-12 mx-auto;
}
.example-box ul li{
  @apply sm:basis-1/2 md:basis-1/4;
}
.example-box ul li img{
  @apply w-4/5 mx-auto sm:w-full mb-8;
}

.example-box ul li p{
  @apply w-4/5 mx-auto text-center md:h-[100px] mb-8;
}

.example-area .notice{
  @apply md:w-4/5 bg-white font-bold text-left sm:text-center rounded-3xl mx-8 md:mx-auto mb-12 md:mb-60 text-slate-700 text-lg md:text-xl lg:text-2xl font-black py-8 px-8 md:leading-10;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

.faq-body{
  @apply bg-neutral-100 pb-8 relative pt-24
}

.faq-body:before{
  @apply content-[''] w-full block bg-transparent bg-no-repeat bg-bottom bg-contain absolute;
  top: -6rem;
  height: 6rem;
  background-image: url('images/frame-wave-top-gray.svg');
}


.faq-box{
  @apply bg-white xl:w-4/5 mx-8 rounded-3xl xl:mx-auto pt-8 md:pt-12 pb-8 md:pb-4 mb-8 sm:mb-20;
}

.faq-body h4{
  @apply text-rhino-950 text-xl sm:text-3xl md:text-4xl font-bold py-3 relative w-4/5 md:w-3/4 lg:w-1/2 mx-auto mb-12 xl:mb-32 border-b-4 md:border-b-[10px];
  font-family: 'Zen Kaku Gothic New', sans-serif;
  border-bottom-color:#B7F6FF;
  width: fit-content;
}

/* .faq-body h4::after{
  @apply content-[''] w-4/5 h-[10px] block bg-transparent bg-no-repeat bg-bottom bg-contain absolute rounded-full;
  background-color: #B7F6FF;
  top: 4rem;
  left: 10%;
} */

.faq-body ul li dl{
  @apply xl:w-4/5 mx-auto mb-32 xl:mb-32;
}
.faq-body ul li dl dt{
  @apply text-rhino-950 text-xl sm:text-2xl font-bold py-4 relative w-4/5 mx-auto text-left ps-[60px] sm:ps-[90px] mb-4;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

.faq-body ul li dl dt:before{
  @apply content-[''] w-full block bg-no-repeat bg-bottom bg-contain absolute w-[60px] h-[60px] bottom-[60px] md:bottom-[150px] mx-auto left-[-8px] sm:left-0 top-[5px];
  background-image: url('images/icon-faq-q.svg');
}

.faq-body ul li dl dd{
  @apply text-base font-medium pt-5 relative w-4/5 mx-auto text-left  ps-[60px] sm:ps-[90px];
}

.faq-body ul li dl dd a{
  @apply  text-base font-medium;
  color: #0AD9F5;
}

.faq-body ul li dl dd.a:before{
  @apply content-[''] w-full block bg-no-repeat bg-bottom bg-contain absolute w-[60px] h-[60px] bottom-[60px] md:bottom-[150px] mx-auto left-[-8px] sm:left-0 top-[5px];
  background-image: url('images/icon-faq-a.svg');
}

.faq-body .notice{
  @apply bg-white xl:w-4/5 mx-8 rounded-3xl md:mx-auto p-8 md:pt-12 md:pb-12 mb-8 sm:mb-20 text-white text-xl text-left md:text-center;
  background-color: #0AD9F5;
}
